:root {
  --teal: #12C6A8;
  --indigo: #5A58F8;
  --light-gray: #F2F2F2;
  --light-gray-10-percent: #DADADA;
  --dark-gray: #7F7F7F;
  --black-30: rgb(30, 30, 30);
  --danger: #EF4B5B;
}

body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: initial;
}

.ui.header {
  font-weight: bold;
  letter-spacing: 1px;
  font-family: inherit;
  color: inherit;
}

.swagger-ui,
.swagger-ui section.models h4,
.swagger-ui .model-title,
.swagger-ui table thead tr td,
.swagger-ui table thead tr th,
.swagger-ui .parameter__name,
.swagger-ui .response-col_status,
.swagger-ui .dialog-ux .modal-ux-header h3,
.swagger-ui .dialog-ux .modal-ux-content p,
.swagger-ui label,
.swagger-ui .opblock-tag,
.swagger-ui .btn,
.ui.form input:not([type]), .ui.form input[type=date], .ui.form input[type=datetime-local], .ui.form input[type=email], .ui.form input[type=file], .ui.form input[type=number], .ui.form input[type=password], .ui.form input[type=search], .ui.form input[type=tel], .ui.form input[type=text], .ui.form input[type=time], .ui.form input[type=url] {
  font-family: inherit;
  color: inherit;
}

.swagger-ui .parameter__extension,
.swagger-ui .parameter__in {
  color: var(--dark-gray);
}

.swagger-ui .model,
.swagger-ui .parameter__type,
.swagger-ui .dialog-ux .modal-ux-content h4,
.ui.form input:not([type]):focus, .ui.form input[type=date]:focus, .ui.form input[type=datetime-local]:focus, .ui.form input[type=email]:focus, .ui.form input[type=file]:focus, .ui.form input[type=number]:focus, .ui.form input[type=password]:focus, .ui.form input[type=search]:focus, .ui.form input[type=tel]:focus, .ui.form input[type=text]:focus, .ui.form input[type=time]:focus, .ui.form input[type=url]:focus, .ui.form textarea:focus,
.ui.table,
.ui.form .field>label,
.ui.input,
.ui.dropdown .menu>.item:hover {
  color: inherit;
}

.ui.basic.button,
.ui.basic.buttons .button,
.ui.basic.active.button,
.ui.basic.buttons .active.button {
  color: inherit !important;
}

.swagger-ui button {
  font-family: inherit;
}

#root {
  font-family: inherit;
  color: black;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ui.cards > .card > .content > .header,
.ui.card > .content > .header,
.ui.cards > .card > .content > .description,
.ui.card > .content > .description {
  font-family: inherit;
  color: black;
}

.ui.table thead th {
  color: black;
}

.ui.text.container {
  font-family: inherit;
}

.ui.menu {
  font-family: inherit;
}

.ui.dropdown .menu>.item {
  color: black;
}

.fixfloat:after {
  content: "";
  display: table;
  clear: both;
}

@keyframes App-logo-spin {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.08);
  }
}

/* fix semantic-ui quirks */
.ui.negative.floating.message,
.ui.attached.negative.floating.message {
  box-shadow:
    0px 0px 0px 1px #E0B4B4 inset,
    0px 2px 4px 0px rgba(34, 36, 38, 0.12),
    0px 2px 10px 0px rgba(34, 36, 38, 0.15)
}

.ui.vertical.menu .menu .item {
  font-size: 14px;
  font-family: Arial,Helvetica,sans-serif;
  padding: 5px 5px 5px 5px;
  margin-left: 20px;
}
.sidebar-list:hover {
    background-color: #000000;
}

.active {
    background-color: #000000;
}
.active a.item.link {
    color: #12C6A8 !important;
}

.sticky-offset {
    margin-top: 30px;
}

.ui.vertical.menu .menu .menu .item {
  margin-left: 1em;
  font-size: 1em;
}

.ui.inverted.menu .menu a.item:hover {
  color: #ffffff;
}

.ui.inverted.menu {
  background: black;
}

.ui.cards .card .image {
  background-color: rgba(0, 0, 0, 0);
}

.ui.cards .blue.card {
  box-shadow: 0px 0px 0px 1px #D4D4D5, 0px 2px 0px 0px #253455, 0px 1px 3px 0px #D4D4D5;
}

.ui.cards .blue.card:hover {
  box-shadow: 0px 0px 0px 1px #D4D4D5, 0px 2px 0px 0px #556a7b, 0px 1px 3px 0px #D4D4D5;
}

.ui.button {
  background-color:  #12C6A8;
  color: black;
  border-radius: 0;
  text-transform: uppercase;
  letter-spacing: .5px;
  font-family: inherit;
}

.ui.button:hover {
  background-color: var(--indigo);
}

.ui.button:active,
.ui.button:focus {
  background-color: black;
}

.ui.button:hover,
.ui.button:active,
.ui.button:focus {
  color: white;
}

#logo {
  margin-right: 60px;
}

#logo:hover {
  background-color: black
}

.ui.inverted.menu .item {
  color: white;
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: bold;
}

.ui.inverted.menu .link.item:hover,
.ui.inverted.menu a.item:hover,
.ui.inverted.menu .menu a.item:hover {
  color: #12C6A8;
  background: unset;
}

.ui.vertical.inverted.menu .menu .item {
  color: white;
  background: unset;
}


a,
a:hover {
  color: var(--indigo);
}

a:not(.card):hover {
  text-decoration: underline;
}

.showHideButton {
  width: 88px;
}

/* Toast message */
.Toastify__toast {
  color: black;
  font-family: inherit;
}

/* Modal */
.ui.modal>.header,
.ui.modal>.content {
  font-family: inherit;
  color: black;
}

.swagger-ui {
  font-size: 13px;
  font-weight: 600;
  font-family: Arial,Helvetica,sans-serif;
  color: #000000;
}

.swagger-ui .highlight-code>.microlight {
  overflow-y: auto;
  width: 680px;
  max-height: 334px;
  min-height: 6em;
  border-radius: 0px;
  font-family: Arial,Helvetica,sans-serif;
}

.swagger-ui textarea {
  width: 680px;
  min-height: 280px;
  padding: 10px;
  border: none;
  border-radius: 0px;
  outline: none;
  background: #ffffff;
  font-family: Arial,Helvetica,sans-serif;
  color: #000000;
}

.swagger-ui .model {
  font-size: 13px;
  font-family: Arial,Helvetica,sans-serif;
  color: #000000;
}

.swagger-ui .model-box {
  padding: 10px;
  width: 680px;
  display: inline-block;
  border-radius: 0px;
  background: #ffffff;
  font-family: Arial,Helvetica,sans-serif;
}

.swagger-ui textarea.curl {
  width: 975px;
  min-height: 180px;
  margin: 0px;
  padding: 0px;
  resize: none;
  border-radius: 0px;
  background: #000000;
  font-family: Arial,Helvetica,sans-serif;
  color: #ffffff;
}

.swagger-ui .opblock-body pre {
  margin: 0;
  padding: 10px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  border-radius: 0px;
  background: #000000;
  overflow-wrap: break-word;
  font-family: Arial,Helvetica,sans-serif;
  color: #ffffff;
}

.swagger-ui .response-col_description__inner div.markdown, .swagger-ui .response-col_description__inner div.renderedMarkdown {
  width: 680px;
  display: block;
  margin: 0;
  padding: 10px;
  border-radius: 0px;
  background: #000000;
  font-family: Arial,Helvetica,sans-serif;
  color: #ffffff;
}

.swagger-ui .prop-type {
  color: #5A58F8;
}

.swagger-ui table.headers td {
  font-size: 12px;
  vertical-align: middle;
  font-weight: 600;
  font-family: Arial,Helvetica,sans-serif;
  color: #000000;
}

.swagger-ui table thead tr td, .swagger-ui table thead tr th {
  font-size: 13px;
  padding: 12px 0;
  text-align: left;
  border-bottom: 1px solid rgba(59,65,81,.2);
  font-family: Arial,Helvetica,sans-serif;
}

.swagger-ui label {
  font-size: 13px;
  font-weight: 600;
  margin: 0 0 5px;
  font-family: Arial,Helvetica,sans-serif;
  color: #000000;
}

.swagger-ui .parameter__type {
  font-size: 14px;
  padding: 5px 0;
  font-family: Arial,Helvetica,sans-serif;
  font-weight: 600;
  color: #000000;
}

.swagger-ui .opblock .opblock-summary-description {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  word-break: break-word;
  font-family: Arial,Helvetica,sans-serif;
  color: #000000;
}

.swagger-ui .opblock .opblock-summary-operation-id, .swagger-ui .opblock .opblock-summary-path, .swagger-ui .opblock .opblock-summary-path__deprecated {
  font-size: 16px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  word-break: break-word;
  padding: 0 10px;
  font-family: Arial,Helvetica,sans-serif;
  color: #000000;
}

.swagger-ui .opblock .opblock-section-header h4 {
  -ms-flex: 1;
  flex: 1 1;
  margin: 0;
  font-family: Arial,Helvetica,sans-serif;
  color: #000000;
}

.swagger-ui .opblock .opblock-section-header>label {
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  margin-left: auto;
  font-family: Arial,Helvetica,sans-serif;
  color: #000000;
}

.swagger-ui .opblock-description-wrapper, .swagger-ui .opblock-external-docs-wrapper, .swagger-ui .opblock-title_normal {
  font-size: 12px;
  margin: 0 0 5px;
  padding: 15px 20px;
  font-family: Arial,Helvetica,sans-serif;
  color: #000000;
}

.swagger-ui .responses-inner h4, .swagger-ui .responses-inner h5 {
  font-size: 14px;
  margin: 10px 0 5px;
  font-family: Arial,Helvetica,sans-serif;
  color: #000000;
  font-weight: 500;
}

.ui.upward.dropdown>.menu {
  top: auto;
  bottom: 100%;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, .08);
  border-radius: .28571429rem .28571429rem 0 0;
  overflow: auto;
  height: 300px;
}

.ui.button.dropdown .menu {
  min-width: 100%;
  overflow: auto;
  height: 300px;
}
